// AdminSunKing.scss

.form-sunking {
    width: 60%;
    margin: auto;
    padding: 20px;
    background-color: #f5f5f5;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
    h2 {
      color: #333;
      text-align: center;
      margin-bottom: 20px;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      label {
        margin-bottom: 8px;
        font-weight: bold;
        color: #555;
      }
  
      input,
      textarea {
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 14px;
      }
      textarea {
        height: 25rem;
        resize: none;
      }
      button {
        background-color: #007bff;
        color: #fff;
        padding: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #0056b3;
        }
      }
    }
  }
  