@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";


.video-list-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: $primaryBackground;


    h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    ul {
        list-style-type: none;
        padding: 0;

        .video-item {
            border: 1px solid #ccc;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 5px;
            h2 {
                font-size: 20px;
            }

            p {
                margin-bottom: 10px;

                strong {
                    font-weight: bold;
                }

                a {
                    color: blue;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
.video-list-container {
    padding: 20px;
}

.cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
   
    // gap: 20px;
    
    img{
        width: 6.25rem;
        height:10rem ;
    }
}

.card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    width: 300px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    cursor: pointer;
    background-color: white;
    display: flex;
    flex-direction: column; 
    height: 20rem;

    &-description {
        flex: 1;
            padding: 1rem;
            background-color: $secondaryBackground;
            display: flex;
            flex-direction: column;
            margin-bottom: 0; height: 6rem;
        h2 {
            font-size: 1.5rem;
            margin-bottom: 0.5rem;
        }
        p {
            font-size: 1rem;
            margin-bottom: 0;
           
        }
    }
}

