@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.video-list-container-snippet {
  @include flex(center, flex-end, column);
  padding-top: 3rem;
  width: 100%;
  font-family: Arial, sans-serif;
  background-color: $primaryBackground;
  padding: 1rem 1rem 1rem 1rem;

  @include mobile {
    @include flex(center, center, column);

  }
  ul {
    list-style-type: none;
    padding: 0;
  }
// Visible in desktop only
  &__cards-container {
    @include flex(center, center, row);
    overflow: hidden;
    // min-width: 80%;
    height: 21rem;
    @include tablet{
      display: none;
      width: 80%;
      height: auto;
    }
    @include mobile {
      display: none;
      width: 80%;
      height: auto;
    }
  }
  // Visible in tablet mobile only
&__cards-container-mobile {
  @include flex(center, center, row);
  overflow: hidden;
  min-width: 80%;
  height: 21rem;
  @include desktop{
    display: none;
    width: 80%;
    height: auto;
  }
  @include tablet{
    width: 100%;
    height: auto;
  }
  @include mobile {
    width: 80%;
    height: auto;
  }
}
}



.card-snippet { // List of photos
  @include flex(center, center, column);
  border: 1px solid #ddd;
  width: 60%;
  overflow: hidden;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  background-color: white;
  height: 20rem;
  @include tablet {
    width: 100%;
    height: 16rem;
  }
  @include mobile {
    width: 100%;
    height: 10rem;

    &__content {
      img {
        width: 50%;
        height: auto;
        object-fit: cover;
      }
    }
  }

  &__content { // Photo
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    width: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 1rem;
      margin-bottom: 0;
    }
  }

  @include desktop {
    width: 50%;
  }

  @include tablet {
    width: 80%;
    height: 16rem ;
  }
}