@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.button-wrap {
  @include flex(center, center, column);
  padding: 1rem;
  border-radius: 10px;
  width: 100%;
  background-color: $secondaryBackground;
  color: rgb(23, 35, 46);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  @include tablet {
    @include flex(center, center, column);
  }
  &__free-button {
    @include flex(center, center, column);
    width: 100%;
    background-color: $secondaryBackground;
    height: 200px; // height matches voting page parent
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    height: 46rem; // height matches UserProfile "user-profile"
    padding: 0 0.5rem;

    @include tablet {
      height: auto;
    }
    @include mobile {
      @include flex(center, center, column);
      width: 100%;
      height: auto;
      padding: 0 1rem;
    }
  }
  // Paid buttons
  &__button-container {
    @include flex(center, center, row);
    flex-wrap: wrap;
    margin: 1rem;
  }
}
.payment-option {
  @include flex(center, center, column);
  margin: 1rem;
  text-align: center;

  p {
    // margin-bottom: 0.5rem; 
    padding: 0;
    margin: 0;
  }
}
.payment-option-double {
  @include flex(center, center, column);
  margin: 1rem;
  text-align: center;

  p {
    // margin-bottom: 0.5rem; 
    color: red;
    padding: 0;
    margin: 0;
  }
}
.payment-option-triple{
  @include flex(center, center, column);
  margin: 1rem;
  text-align: center;

  p {
    // margin-bottom: 0.5rem; 
    color: green;
    padding: 0;
    margin: 0;
  }
}

.disabled-button {
  pointer-events: none;
  opacity: 0.5;
}
