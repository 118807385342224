$color1: #80222b;

$backgroundcolor3: #f0ddc5; // Egypt Orange
$primaryBackground: #e6e6e6; // grey
$secondaryBackground: rgb(239, 205, 205); // Theatre Red
$tertiaryBackground: #524f4f;
$forthBackground:  #fbfbfb; 

$second-color: #b9ceeb;
$color5: #ff8267;
$third-color: #87a8d0;
$fourth-color: #c3b4d2;
$fifth-color: #d4a5a5;
$primary-color: #242222;
$fifth-color: #d4a5a5;
