@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.form-background{
    // padding-top: 2rem;
    background-color: $primaryBackground;
    // height: 80vh;
    min-height: auto;
    margin-bottom: 1rem;
 
}

.google-signin-button {
    background-color: #ed6f0f;
    color: white;
    border: none;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    display: block;
    width: 100%;
    text-align: center;
  }
  
  .google-signin-button:hover {
    background-color: #cf4715;
  }
  