@use "../../styles/mixins" as *;
@use "../../styles/colors" as *;

.vote-profile {
  width: 100%;
  border-radius: 8px;
  // overflow: hidden;
  background-color: $secondaryBackground;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: 46rem; // height matches VotingComponent "button-wrap__free-button"
  @include desktop-sm{
    display: block;
  }
  @include tablet {
    height: 34rem;
  }
  margin-bottom: 1rem;
  &__top-three-messages {
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 500;
    @include mobile {
      font-size: 0.8rem;
      padding: 1rem;
    }
  }
  &__top-three-announce {
    h3 {
      @include flex(center, center, row);
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 500;
      @include mobile {
        font-size: 0.8rem;
        padding: 1rem;
      }
    }
  }

  @include tablet {
    height: auto;
  }

  @include mobile {
    height: 40rem;
  }

  &__contestant-wrapper {
    // width: 100%;
    height: auto;
    @include flex(center, center, column);
    @include mobile {
      width: 100%;
    }
  }
}
.vote-video-container {
  @include flex(center, center, row);
  width: 100%;
  // max-width: 600px;
  height: auto;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  @include desktop {
    width: 80%;
    height: auto;
  }

  @include desktop-sm {
    margin-top: 1rem;
    width: 100%;
    height: auto;
  }
  @include tablet {
    margin-top: 1rem;
    width: 100%;
    max-width: 550px;
    height: auto;
  }
  @include mobile {
    width: 100%;
    height: auto;
  }

  iframe {
    border-radius: 8px;
    width: 100%;
    height: auto;
    min-height: 350px;
    @include desktop-sm {
      max-width: 100%;
      height: auto;
      margin-top: 1rem;
    }
    @include tablet {
      min-height: 350px;
      margin-top: 1rem;
    }
    @include mobile {
      height: auto;
      width: 100%;
      aspect-ratio: 16/9;
    }
  }
}

.vote-user-info {
  @include flex(center, flex-start, row);
  flex-wrap: wrap;
  width: 700px;
  border-radius: 0 0 8px 8px;
  // overflow: hidden;
  padding: 1rem;
  // height: 200px; // for 400 words to fit
  height: 20rem;
  // overflow:scroll ;
  // overflow-x: hidden;
  @include desktop-sm {
    
    width: 100%;
    max-width: 550px;
  }
  @include tablet {
    @include flex(center, center, column);
    width: 500px;

    height: auto;
  }
  @include mobile {
    height: 400px;
    width: 400px;

    @include flex(center, center, column);
    padding: 1rem;
  }
}
.vote-user-details {
  // padding-right: 1rem;
  // margin-left: 1rem;
  margin-right: 1rem;
  width: 50%;
  // width: 300px;
  max-width: 400px;
  h2 {
    padding: 0%;
  }
 @include tablet{
  margin: 0;
 }
  @include mobile {
    width: 80%;
    padding: 0;
    margin: 0;
   
    @include flex(center, center, column);
    h2 {
      font-size: 1.3rem;
    }
  }
}

.vote-user-headshot {
  width: 100px;
  height: auto;
  object-fit: cover;
  margin-top: 1.5rem;

  @include mobile {
    width: 50px;
    height: 80px;
    padding: 0%;
    margin-top: 1rem;
  }
}

.vote-user-description {
  width: 100%;
  color: black;
  margin-right: 1rem;
  overflow: hidden;

  @include tablet {
    margin: 0;
    padding: 0;
  }

  @include mobile {
    margin: 0;
    padding: 0;
  }
}

.social-icons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.social-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.custom-controls {
  @include flex(center, center, row);
  bottom: 10px;
  background-color: $primaryBackground;
  padding: 10px;
  border-radius: 5px;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: filter 0.3s ease;
    border: 1px black solid;
    border-radius: 5px;

    &:hover {
      filter: brightness(0.8);
    }
  }
}
.disabled {
  opacity: 0.5; /* or any other styling to indicate disabled state */
  pointer-events: none;
}

.link-style {
  text-decoration: none; // Remove underline
  color: inherit; // Inherit color
  cursor: pointer; // Set cursor to pointer
}
