@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.footer {
  position: relative; 
  text-align: center;
  height: 8rem;
  overflow: hidden;      


  &__top {
    padding: 1rem;
    @include flex(center, center, row);

    @include tablet {
      padding: 1.5rem 1.5rem 0 1.5rem;
    }

    @include desktop {
      padding: 2rem 2rem 0 2rem;
    }
  }

  &-title {
    padding: 0 1rem;
    p {
      margin: 0;
    }
  }

  &__links {
    padding: 1rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    a {
      text-decoration: none;
      color: white;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
