@import "./styles/colors";
@import "./styles/mixins" ;

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: $tertiaryBackground;
}
