@use "../../styles/mixins" as *;

.admin-list-container {
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: rgb(255, 255, 255);

    h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    ul {
        list-style-type: none;
        padding: 0;

        .video-item {
            border: 1px solid #ccc;
            padding: 10px;
            margin-bottom: 10px;
            border-radius: 5px;

            h2 {
                font-size: 20px;
            }

            p {
                margin-bottom: 10px;

                strong {
                    font-weight: bold;
                }

                a {
                    color: blue;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
    button {
        background-color: #d3d3d3; // Grey button background color
        color: #333; // Dark grey text color
        border: none;
        padding: 10px 20px; // Increase padding for wider buttons
        border-radius: 5px;
        cursor: pointer;
        font-size: 16px;

        &:hover {
            background-color: #c0c0c0; // Darker grey on hover
        }

        &.active {
            background-color: #666; // Dark background color for the active button
            color: #fff; // White text color for the active button
        }
    }
}

.admin-list-container {
    
    padding: 20px;
}

.admin-cards-container {

    img{
        width: 6.25rem;
        margin: 1rem;
    }
}

.admin-card {
    border: 1px solid #865050;
    border-radius: 8px;
    width: 100%;
    @include flex(flex-start, center,row);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    margin-bottom: 20px;cursor: pointer;
  
}
.admin-card-content{
    @include flex(flex-start, center,row);

}
.admin-navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px; // Adjust gap as needed
}