@use "../../styles/mixins" as *;
@use "../../styles/colors" as *;

.user-profile {
  @include flex(center, center, column);
  // border: 3px solid blue;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  background-color: $secondaryBackground;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  height: auto;
  margin-bottom: 1rem;
  &__top-three-messages {
    // color: darkred;
    margin-top: 1rem;
    font-size: 1rem;
    font-weight: 500;
    @include mobile {
      font-size: 0.8rem;
      padding: 1rem;
    }
  }

  @include tablet {
    height: 50rem;
  }

  @include mobile {
    height: 40rem;
    border: 3px solid blue;

  }

  &__contestant-wrapper {
    // width: 100%; 
    height: auto;
    @include flex(center, center, column);
    @include mobile {
      height: 700px;
    }
  }
}

.video-container {
  @include flex(center, center, row);
  width: 80%;
  max-width: 700px;
  height: 50vh;
  height: auto;
  overflow: hidden;
  border-radius: 8px 8px 0 0;
  @include desktop-sm {
    width: 100%;
    height: auto;
  }
  @include tablet {
    width: 100%;
    max-width: 400px;
    height: auto;
  }
  @include mobile {
    width: 100%;
    height: auto;
    max-width: 450px;

  }

  iframe {
    // position: relative;
    border-radius: 8px;
    width: 100%;
    
    @include desktop-sm {
      width: 100%;
      height: 400px;
    }
    @include tablet {
      width: auto;
     min-width: 400px;
      // max-width: 650px;
       height: auto;
       min-height: 250px;
       margin: 0 auto; 
    }
    @include mobile {
      padding-left: 1rem;
      padding-right: 1rem;
      width: 100%;
      // height: 200px;
      max-width: 420px;

    }
  }
}

.user-info {
  @include flex(center, center, row);
  flex-wrap: wrap;
  width: 700px;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  padding: 1rem;
  height: 200px;
  @include tablet {
    @include flex(center, center, column);
    width: 500px;

    height: auto;
  }
  @include mobile {
    height: auto;
    width: 300px;

    @include flex(center, center, column);
    padding: 1rem;
  }
}
.user-details {
  // padding-right: 1rem;
  // margin-left: 1rem;
  
  width: 50%;
  // width: 300px;
  max-width: 400px;
  h2 {
    padding: 0%;
  }

  @include mobile {
    width: 80%;
    padding-right: 0;
    padding-top: 0;
    margin-top: 0;
    @include flex(center, center, column);
    h2 {
      font-size: 1.3rem;
    }
    
  }
}

.user-headshot {
  width: 100px;
  height: auto;
  object-fit: cover;

  @include mobile {
    width: 50px;
    height: 80px;
    padding: 0%;
    margin-top: 1rem;
  }
}

.user-description {
  
  width: 100%;
  color: black;
  overflow: hidden;
  margin-right: 1rem;
  @include tablet {
    margin: 0;
    padding: 0;
  }
  @include mobile {
    margin: 0;
    padding: 0;
  }
}

.social-icons {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.social-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}
.custom-controls {
  @include flex(center, center, row);
  bottom: 10px;
  background-color: $primaryBackground;
  padding: 10px;
  border-radius: 5px;

  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
    transition: filter 0.3s ease;
    border: 1px black solid;
    border-radius: 5px;

    &:hover {
      filter: brightness(0.8);
    }
  }
}
.disabled {
  opacity: 0.5; /* or any other styling to indicate disabled state */
  pointer-events: none;
}

.link-style {
  text-decoration: none; // Remove underline
  color: inherit; // Inherit color
  cursor: pointer; // Set cursor to pointer
}
.share-button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
    margin-bottom: 1rem;
}
.share-button:hover {
  background-color: #0056b3;
}