@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.nav {
  width: auto;
  background-color: black; 
  @include flex(center, center, row);
  margin-left: 0;
  @include desktop{
    @include flex(center, center, row);
  }
  @include tablet{
    width: auto;
    position: fixed;
    top: 0;
    left: 0;
    margin-right: 1rem;
    height: 100vh; 
    background-color: black; 
    z-index: 1001; 
    @include flex(center, center, row);
    margin-left: 0;
  }
  @include mobile{
    width: auto;
    position: fixed;
    margin-right: 1rem;
    top: 0;
    left: 0;
    height: 100vh; 
    background-color: black; 
    z-index: 1001; 
    @include flex(center, center, row);
    margin-left: 0;
  }
  &-share-profile {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    padding: 1rem;
    width: 125px;

    @include tablet {
      box-shadow: 0 4px 8px rgba(255, 254, 254, 0.5);
    }
@include mobile {
      box-shadow: 0 4px 8px rgba(255, 254, 254, 0.5);
    }

  }
  &-sunking {
   
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    @include tablet {
      box-shadow: 0 4px 8px rgba(255, 254, 254, 0.5);
    }
@include mobile {
      box-shadow: 0 4px 8px rgba(255, 254, 254, 0.5);
    }
    padding: 1rem;
    width: 125px;


  }
  .close-btn {
    width: 20px; 
    height: auto; 
    cursor: pointer;
    margin-top: 2rem; 
    @include desktop {
      display: none; 
    }
  }
  ul {
    list-style: none;
    @include flex(flex-start, center, row);
    padding: 1rem;
    margin: 0;
    height: 100%; 
    @include tablet {
      @include flex(center, center, column);
      height: auto; 
    }
    @include mobile {
      @include flex(center, center, column);
      height: auto; 
    }
  }
  li {
    margin-bottom: 1rem;
    color: white;
    cursor: pointer;
    text-align: center; 
    @include desktop {
      color: black; 
       padding: 1rem;
    }
    @include tablet {
      // padding-bottom: 1rem;
    }
    @include mobile {
      padding-bottom: 1rem;
    }
    a {
      color: inherit;
      text-decoration: none;
      display: block;
      text-align: center;
      cursor: pointer;
    }
  }
  @include desktop {
    background-color: transparent; 
    @include flex(center, center, row); 
  }
  &-enter {
    padding: 0;
    margin: 0;
  }
}
.nav-logout {
  text-align: center;
  @include desktop {
    margin-right: 1rem;
  }
  @include tablet {

    margin-bottom: 0;
    padding-bottom: 0;
  

  }
  @include mobile {
    padding-top: .625rem;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
