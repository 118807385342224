// Design of component inspired by https://nicepage.com/css-templates/preview/its-easy-to-make-beautiful-1768017?device=desktop&fbclid=IwAR02v6vlMf43GoXDG5cV0xw1i_a2YjZgeQ-jwYYUI_lLqm6gGI3oWbILtPw

@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.baner {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: $primaryBackground;
  margin: 0;
  height: auto;
  @include flex(center, flex-start, row);
@include desktop-sm {
  @include flex(center, center, column);
}
  @include tablet {
    @include flex(center, center, column);
  }
  @include mobile {
    @include flex(center, center, column);
  }

  &-text {
    @include flex(flex-start, flex-start, column);
    width: 60%;
    min-height: 50rem;
    height: auto;
    background-color: $secondaryBackground;
    padding: 1rem 5rem 4rem 5rem;
    z-index: 4;
    @include desktop-sm {
      width: 80%;
      min-height: 50rem;
      height: auto;
    }
    @include tablet {
      @include flex(flex-start, flex-start, column);
      width: 100%;
      height: auto;
      min-height: auto;
      margin-bottom: 1rem;
      z-index: 1;
    }

    @include mobile {
      @include flex(center, flex-start, column);
      min-height: auto;

      width: 100%;
      height: auto;
      padding: 1rem 2rem;
    }
    &-titles {
      width: 100%;
    }
  }

  &-image {
    width: 40%;
    height: auto;
    padding: 2rem;
    padding-right: 2.5rem;
    position: relative;
    z-index: 6;

    @include tablet {
      width: 90%;
      @include flex(center, center, column);
      z-index: 1;
      margin-bottom: 1rem;
      padding: 0;
    }
    @include mobile {
      width: 100%;
      height: auto;
      padding: 1rem 0;
      text-align: center;
    }
    img {
      width: 80%;
      border-radius: 100%;
      height: auto;
      opacity: 0;
      animation: fadeInAndSlide 2s forwards;
      position: absolute;
      margin-top: 100px;
      top: -80px;
      left: -40px;
      z-index: 2;
      @include tablet {
        width: 90%;
        height: auto;
        margin: 0;
        position: static;
      }
      @include mobile {
        width: 12rem;
        height: 12rem;
        margin: 0;
        position: static;
      }
@include desktop-sm {
  margin-top: 10px;
  left: 0px;

      }
      @include tablet {
       
        top: 0%;
        left: 0;

      }
       @include mobile {
  
        top: 0%;
        left: 0;

      }
    }
  }

  h2 {
    color: rgb(0, 0, 0);
    font-size: 2rem;
    padding-top: 3rem;
    margin: 0;
    @include mobile {
      font-size: 1rem;
    }

    @include tablet {
      font-size: 1.5rem;
    }
  }

  h1 {
    color: rgb(0, 0, 0);
    font-size: 3rem;
    padding: 0;

    @include mobile {
      font-size: 1.5rem;
    }

    @include tablet {
      font-size: 2rem;
    }
  }

  p {
    font-weight: 300;
    margin: 0;

    @include mobile {
      font-size: 1rem;
    }

    @include tablet {
      font-size: 1rem;
    }

    @include desktop {
      font-size: 1.5rem;
    }
  }

  @keyframes fadeInAndSlide {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
