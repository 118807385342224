@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.header {
  background-color: $forthBackground;
  // position: relative;
  // height: auto;
  overflow: hidden;
  padding-bottom: 0;

  &--top {
    padding: 1rem;
    @include flex(space-between, center, row);
    // border: 2px blue solid;

    @include desktop {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 2rem;
    }

    h1 {
      @include tablet {
        font-size: 1.3rem;
        text-align: center;
      }
      @include mobile {
        font-size: 1rem;
        text-align: center;
        justify-content: center;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
    &__left {
      @include flex(flex-start, center, row);
      width: 50%;
      @include tablet {
        width: 80%;
      }
      @include mobile {
        width: 80%;
      }
    }
    .header-menu {
      display: flex;
      align-items: center;
      // width: 5rem;
      height: auto;
      // border: 2px red solid;
      z-index: 5;
    }
    img {
      width: 4.5rem;
      height: auto;
    }

    @include tablet {
      padding: 1.5rem;
      text-align: left;
    }

    @include desktop {
      padding: 2rem;
    }
  }

  @include tablet {
    &--top {
      padding: 1.5rem;
    }
  }

  @include desktop {
    &--top {
      padding: 2rem;
    }
  }

  &-logo {
    width: auto;
    height: auto;
    // border: 2px red solid;
    @include desktop-sm {
      width: auto;
    }
    @include desktop {
      width: auto;
    }
    @include tablet {
      width: auto;
    }
    @include mobile {
      width: auto;
    }
  }

  &-title {
    @include flex(flex-start, center, column);
    padding: 0 1rem;
    // z-index: 1;
    width: 60%;
    @include mobile {
      width: 100%;
    }
    img {
      width: 100%;
      height: auto;
      @include tablet {
        width: 100%;
      }
      @include mobile {
        width: 100%;
      }
    }
    h1 {
      padding: 0;
      margin: 0;
    }
    h2 {
      padding: 0;
      margin: 0;
    }
    // border: 2px red solid;
  }

  // Mobile Menu
  &.mobile-menu-open {
    .header--top {
      .header-menu {
        .nav {
          display: block;
        }
      }
    }
  }

  @include mobile {
    .header--top {
      .header-menu {
        .nav {
          display: none;
        }

        .menu-icon {
          display: block;
          width: 40px;
          height: 40px;
          color: white;
          border: none;
          padding: 0.5rem;
          cursor: pointer;
          font-size: 1rem;
        }
      }
    }
  }
  @include tablet {
    .header--top {
      .header-menu {
        .nav {
          display: none;
        }

        .menu-icon {
          display: block;
          width: 40px;
          height: 40px;
          color: white;
          border: none;
          padding: 0.5rem;
          cursor: pointer;
          font-size: 1rem;
        }
      }
    }
  }
  @include desktop {
    .header--top {
      .header-menu {
        .menu-icon {
          display: none;
        }
      }
    }
  }
}
