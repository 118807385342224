// Design of component inspired by https://nicepage.com/css-templates/preview/its-easy-to-make-beautiful-1768017?device=desktop&fbclid=IwAR02v6vlMf43GoXDG5cV0xw1i_a2YjZgeQ-jwYYUI_lLqm6gGI3oWbILtPw

@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.baner-snippet {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: $primaryBackground;
  width: 100%;
  margin: 0;
  @include flex(center, flex-start, row);
  @include mobile {
    @include flex(center, center, column);
    width: 100%;
    // padding-top: 1rem;
  }
  &-text {
    @include flex(center, flex-start, column);
    width: 70%;
    background-color: $secondaryBackground;
    padding: 1rem 2rem;
    @include tablet {
      height: auto;
    }
    @include mobile {
      height: auto;
      @include flex(center, flex-start, column);
    }
  }

  &-image {
    width: 30%;
    padding: 2rem;
    padding-right: 2.5rem;
    position: relative;
    z-index: 1;
    @include mobile {
      padding: 0;
      padding-bottom: 1rem;
      padding-top: 1rem;
      width: 100%;
      @include flex(center, center, column);
    }
    img {
      @include tablet {
        width: 10rem;
        border-radius: 100%;
        width: 10rem;
        height: 10rem;
        opacity: 0;
        animation: fadeInAndSlide 2s forwards;
        position: absolute;
        top: 80%;
        left: -40px;
        z-index: 2;
      }
      @include desktop {
        width: 10rem;
        border-radius: 100%;
        width: 15rem;
        height: 15rem;
        opacity: 0;
        animation: fadeInAndSlide 2s forwards;
        position: absolute;
        top: 80%;
        left: -60px;
        z-index: 2;
      }
      @include mobile {
        padding: 0;
        padding-top: 1rem;
        border-radius: 100%;
        height: 10rem;
      }
    }
  }

  h2 {
    color: rgb(0, 0, 0);
    font-size: 2rem;
    padding: 0;
    margin: 0;
    margin-top: 1rem;
    @include tablet {
      font-size: 1.5rem;
      padding-top: 1rem;
    }
    @include mobile {
      font-size: 1rem;
      padding-top: 1rem;
    }
  }

  h1 {
    color: rgb(0, 0, 0);
    font-size: 3rem;
    padding: 0;
    margin: 0.5rem 0;
    @include tablet {
      font-size: 2rem;
    }
    @include mobile {
      font-size: 1.5rem;
    }
  }

  p {
    font-weight: 300;
    margin: 1rem 0;
    max-height: 12em;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1.5rem;
    @include mobile {
      font-size: 1rem;
    }

    @include tablet {
      font-size: 1.2rem;
    }

    @include desktop {
      font-size: 1.2rem;
    }
  }

  @keyframes fadeInAndSlide {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
button{
  font-style: none;
}
button {
  font-style: none;
  text-decoration: none; // Add this line
  border: none; // Optional: to remove any border
  outline: none; // Optional: to remove focus outlines
  cursor: pointer; // Optional: for consistent button styling
}
a {
  text-decoration: none; // Remove underline for all links
}
.card-button {
  text-decoration: none; // Ensure the underline is removed
}