/* EmailOptions.scss */
@import "../../styles/mixins.scss";

.email-options {
  @include flex(center, center, column);
  height: 100vh;
  font-family: Arial, sans-serif;
  background-color: antiquewhite;
  padding: 20px;

  h2, h3, p {
    text-align: center;
  }
h4{
    color: salmon;
}
  .email-option {
    margin: 10px;
    padding: 10px 20px;
    text-decoration: none;
    color: white;
    background-color: #007bff;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    width: 100%;
    max-width: 300px;

    &:hover {
      background-color: #0056b3;
    }
  }
}

@include mobile {
  .email-options {
    padding: 10px;
  }

  .email-option {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@include tablet {
  .email-options {
    padding: 15px;
  }

  .email-option {
    font-size: 16px;
    padding: 10px 20px;
  }
}

@include desktop {
  .email-options {
    padding: 20px;
  }

  .email-option {
    font-size: 18px;
    padding: 12px 24px;
  }
}
