@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.form-background-upload {
    padding-top: 1rem;
    background-color: $primaryBackground;
    max-height: auto; 
   padding-bottom: 2rem;
h4{
    color: black;
    font-family:'Courier New', Courier, monospace;
}
   p{
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    color: rgb(23, 139, 133);
   }
}
.form-container__char-count{
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    font-size: .6rem;
    color: gray;
    font-family: 'Montserrat', sans-serif;
}