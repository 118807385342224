$btn-background: linear-gradient(to bottom, #e9cfca, #faf8f7);
$btn-hover-background: #ff5733;
$btn-border: grey 1px solid;
$btn-hover-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
$btn-padding: 25px 40px;
$btn-font-size: 20px;
$btn-border-radius: 5px;
$btn-margin: 1rem;

.payment-button {
  background: $btn-background;
  border: $btn-border;
  color: black;
  padding: $btn-padding;
  cursor: pointer;
  font-size: $btn-font-size;
  border-radius: $btn-border-radius;
  margin: $btn-margin;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: $btn-hover-background;
    box-shadow: $btn-hover-shadow;
  }
}
