@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.dashboard {
  @include flex(center, center, column);
  padding: 20px;
  background-color: $primaryBackground;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  padding-bottom: 3rem;

  &__content {
    width: 80%;
    @include desktop-sm{
      width: auto;
    }
    @include mobile{
       @include flex(center, center, column);
    }

  &__title {
    font-size: 26px;
    color: #2c3e50;
    margin-bottom: 15px;
    font-weight: 500;
  }
  // All data - text video photo
  &__user-data {

    @include flex(flex-start, space-between, row);
    width: 100%;
    @include desktop-sm{
      @include flex(center, center, column-reverse);
    }
    
    @include tablet{
      @include flex(center, center, column-reverse);
    }
    @include mobile{
      @include flex(center, center, column-reverse);
    }

    &__details{
      width: 40%;
      @include flex(center, center, row);
@include tablet{
  width: 100%;
  @include flex(center, center, column);

}
      @include mobile{
        width: 100%;  @include flex(center, center, column);

      }
    }
    &__vid {
      width: 60%;
      height: auto;
      @include flex(center, center, row);
      @include desktop-sm{
        width: 100%;
        margin-bottom: 1rem;
      }
      @include tablet{
        width: 100%;
        margin-bottom: 1rem;
      }
      @include mobile{
        width: 100%;
        margin-bottom: 1rem;
      }
    }
    iframe{
      position: relative;
      width: 100%;
      height: 400px;
      @include desktop-sm{
        margin-left: 1rem;
        height: 300px;
      }

      @include mobile{
        width: 100%;
        height: 200px ;
        max-width: 470px;
        margin: 0;
      }
    }


  }
  }


  &__email-label {
    font-weight: bold;
    color: #34495e;
  }

  &__profile-link {
    display: inline-block;
    margin-top: 10px;
    padding: 8px 15px;
    background-color: #2980b9;
    color: white;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s;

    &:hover {
      background-color: #2471a3;
    }
  }


  &__user-details {
    @include flex(center, center, column);
    max-width: 30%;

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  &__user-name {
    font-size: 20px;
    color: #2c3e50;
  }

  &__user-description {
    color: #7f8c8d;
    margin-top: 5px;
  width: 300px;
  margin-right: 1rem;
@include desktop-sm{
  width: 450px;
}
@include tablet{
  width: 400px;
}
@include mobile{
  width: 100%;
}

  }

  &__user-photo {
    max-width: 100%;
    display: block;
    margin-top: 10px;
    border-radius: 4px;
    @include desktop-sm{
      width:200px ;
    }
  }

  &__user-votes {
    font-weight: bold;
    color: #0d0d0d;
    margin-top: 10px;
  }



  &__logout-button {
    padding: 8px 15px;
    background-color: rgba(231, 76, 60, 0.7);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #c0392b;
    }
  }

  &__error {
    color: #c0392b;
    margin-top: 10px;
  }
  &__edit-button {
    margin-top: 10px;
    padding: 8px 15px;
    background-color: #4e7c9a;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #2980b9;
    }

    &:active {
      background-color: #2980b9;
    }
  }
}
.link-style{
  &__vote-button{
   border: none;
    background-color: #4e7c9a;
    color: white;
    border-radius: 4px;
    padding: 8px 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-top: 10px;
    &:hover {
      background-color: #2980b9;
    }
  };
}
