@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.admin-actor {
  padding: 20px;

  h1 {
    margin-bottom: 20px;
  }

  .filter-buttons {
    margin-bottom: 20px;

    button {
      background-color: #007bff;
      color: #fff;
      border: none;
      padding: 8px 12px;
      border-radius: 5px;
      cursor: pointer;
      font-size: 14px;
      margin-right: 10px;
      margin-bottom: 10px;

      &:hover {
        background-color: #0056b3;
      }
    }
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  &__card {
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s;
    width: 100%;
    max-width: 300px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: bisque;

    &-header {
      padding: 15px;
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      h2 {
        font-size: 18px;
        margin: 0;
        width: 85%;
      }
    }

    &-body {
      padding: 15px;
      flex: 1;

      .admin-actor__card-description {
        margin-bottom: 10px;

        a {
          word-break: break-word;
        }
      }

      .admin-actor__card-video {
        width: 100%;
        max-width: 200px;
        height: auto;
      }
    }

    &-extra {
      padding: 15px;
      border-top: 1px solid #eee;

      &-votes,
      &-round,
      &-group {
        margin: 5px 0;
      }

      &-actions {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .admin-actor__card-update {
          display: flex;
          gap: 10px;
          margin-bottom: 10px;

          input {
            flex: 1;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 5px;
          }

          button {
            background-color: #007bff;
            color: #fff;
            border: none;
            padding: 8px 12px;
            border-radius: 5px;
            cursor: pointer;
            font-size: 14px;

            &:hover {
              background-color: #0056b3;
            }
          }
        }

        .delete-button {
          background-color: #dc3545;
          color: white;
          border: none;
          padding: 8px 12px;
          border-radius: 5px;
          cursor: pointer;
          font-size: 14px;

          &:hover {
            background-color: #c82333;
          }
        }

        .toggle-active-button {
          background-color: #28a745;
          color: white;
          border: none;
          padding: 8px 12px;
          border-radius: 5px;
          cursor: pointer;
          font-size: 14px;

          &:hover {
            background-color: #218838;
          }
        }
      }
    }
  }

  .see-more-button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin: 10px;

    &:hover {
      background-color: #5a6268;
    }
  }
}
