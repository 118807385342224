@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.rules{
    padding: 1rem;
    ul{
        padding-left: 0;
    }
    @include tablet{
        font-size:.7rem ;
    }
    @include mobile{
        font-size: .6rem;
    }
}