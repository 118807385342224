@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.vote {
  background-color: $primaryBackground;
  @include flex(center, center, column);
  overflow: hidden;

  @include tablet {
    width: 100%;
  }
  &-content{
    @include flex(center, center, column);

  }
  &-top {
    @include flex(center, flex-start, row);
    width: 80%;
    height: 46rem; // height matches UserProfile "user-profile" AND VotingComponent "button-wrap__free-button"
    margin-bottom: 1rem;

    @include tablet {
      @include flex(center, center, column);
      width: 80%;
      height: auto;
      margin-bottom: 0;
    }

    @include mobile {
      @include flex(stretch, center, column);
      width: 100%;
      height: auto;
      margin-bottom: 0;
    }
    & > * {
      margin-bottom: 1rem;
    }
    &-left {
      @include flex(flex-start, center, column);
      width: 70%;
      margin-right: 1rem;

      @include tablet {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0

      }
      @include mobile {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0
      
      }
    }
    &-right {
      @include flex(stretch, stretch, column);
      width: 30%;
      @include tablet {
        width: 100%;
      }
      @include mobile {
        width: 100%;
      }
    }
  }

  &-bottom {
    width: 80%;
    margin-bottom: 1rem;
    @include tablet {
      width: 80%;
    }
    @include mobile {
      width: 100%;
    }
  }

  
}

/* VotingPage.scss */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  animation: fadeIn 1s forwards;
}

.success-message {
  display: flex;
  justify-content: center;
  align-items: center;
  // height: 100vh;
  animation: fadeIn 1s forwards, fadeOut 1s forwards 2s;
}


.update-message {
  display: flex;
  justify-content: center;
  align-items: center;
  h4{
    color: white;
    @include flex(center, center, column);
  }
}