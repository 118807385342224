@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";


.home {
  @include flex(center, center, column);

  &-filler1{
    background-color: $primaryBackground;
  }
  &-filler2{
    background-color: white;
    padding: 2rem;
  }
  &-filler1,
  &-filler2
   {
    @include flex(center, center, column);
    height: 8rem;
    width: 80%;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: 20rem;
    @include mobile {
      width: 100%;
      height: auto;
    }

    @include tablet {
      width: 80%;
      height: auto;
    }

    h3 {
      font-size: 1rem;
      color: black;
      font-family: 'Lobster', cursive;
      text-align: center;
      font-size: 2rem;
      font-weight: 500;

      @include tablet {
        font-size: 1.5rem;
        padding: 1rem;
      }

      @include mobile {
        font-size: 1rem;
        padding: 1rem;
      }
    }
  }
  h4{
    padding: 2rem;
    font-size: 1rem;
    font-weight: 500;
    padding-top: 0;
    margin: 0;
  }
  &-signup-link {
    text-decoration: none;
    h4 {
      color: rgb(14, 96, 140);
      text-decoration: underline;
      cursor: pointer;
      size: 2rem;

      &:hover {
        color: darken(rgb(49, 169, 233), 10%);
      }
    }
  }
  &-card
 {
    @include flex(center, center, column);
    margin: 0;
    padding: 0; 
    width: 80%;
    @include mobile {
      width: 100%;
    }

    .card-button {
      margin-top: 1rem;
      padding: 0.5rem 1rem;
      font-size: 2rem;
      font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
      color: #232020;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      margin-bottom: 1rem;

      &:hover {
        background-color: darken($primary-color, 10%);
      }

      @include mobile {
        font-size: 1.5rem;
      }
    }

    .card-button1 {
      background-color: $secondaryBackground;
      float: right;
    }

    .card-button2 {
      background-color: $primaryBackground;
      @include mobile {
        text-align: center; 
        width: 100%; 
        float: none; 
      }
    }

    &-actors {
      @include flex(center, center, row);
      z-index: 4;
    }
  }

  // Responsive Sizes
  @include tablet {
    &-filler1,
    &-filler2 {
      width: 80%;
    }

    &-filler1 {
      height: auto;
    }

    &-filler2 {
      padding: 4rem 6rem;
    }

    &-card1,
    &-card2 {
      width: 80%;
    }
  }

  @include mobile {
    &-filler1,
    &-filler2 {
      width: 100%;
    }

    &-filler1 {
      height: auto;
      padding-top: 1rem;
    }

    &-filler2 {
      padding: 1rem 2rem;
    }

    &-card1,
    &-card2 {
      width: 100%;
    }

    &-card1 {
      margin-bottom: 0; 
    }
  }
}

// Video
.home-promo-video {
padding: 1rem 0;
@include flex(center,center,row);  
  margin: 0;
  background-color: aliceblue;
  width: 80%;
  height: auto;
  @include tablet {
    height: 25rem;
  }
  @include mobile {
    @include flex(center,flex-start,row);
    width: 100%;
    height: 200px;
    padding: 0;
    margin: 0;
  
  }
}

iframe {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  @include mobile {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
  }
}