@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.form-background{
    padding-top: 2rem;
    background-color: $primaryBackground;
    height: auto;
    min-height: 80vh;
    padding-bottom: 2rem;

}