.privacy-policy {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 20px;
    background-color: #f4f4f4;
  
    .container {
      max-width: 800px;
      margin: auto;
      background: #fff;
      padding: 20px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  
      h1,
      h2 {
        color: #333;
      }
  
      p {
        line-height: 1.6;
      }
  
      ul {
        list-style-type: disc;
        margin-left: 20px;
      }
    }
  }
  