@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";

.contact-container {
  @include flex(center, center, column);
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin: 0 auto;

  @include mobile {
    padding: 15px;
  }

  @include tablet {
    padding: 20px;
  }

  @include desktop {
    padding: 25px;
  }

  h4 {
    color: chocolate;
    font-weight: 500;
  }
}

.contact__title {
  font-size: 2rem;
  margin-bottom: 20px;
  color: #333;

  @include mobile {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
}

.contact__subtitle {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #555;

  @include mobile {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }
}

.contact__description {
  text-align: center;
  margin-bottom: 20px;
  color: #666;

  @include mobile {
    font-size: 0.9rem;
    margin-bottom: 15px;
  }
}

.contact__thank-you-message {
  text-align: center;
}

.contact__button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;

  &:hover {
    background-color: #0056b3;
  }
}

.contact__form-group {
  @include flex(column, flex-start, column);
  margin-bottom: 15px;
  width: 100%;
}

.contact__label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.contact__input, .contact__textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    border-color: #007bff;
    outline: none;
  }
}

.contact__textarea {
  resize: vertical;
  min-height: 100px;
}

.contact__form {
  width: 100%;
}
