@import "../../styles/variables";
@import "../../styles/mixins";
@import "../../styles/colors";
.edit {
  box-shadow: 2px 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  padding: 1rem;
  &-dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ecf0f1;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
    @include desktop-sm {
    }
    @include tablet {
    }
    @include mobile{

    }
    h2 {
      color: grey;
    }
    &__success-flash {
      background-color: #2980b9;
      padding: 1rem;
    }
    form {
      display: flex;
      flex-direction: column;

      label {
        font-weight: bold;
        margin-bottom: 8px;
        color: #2c3e50;
      }
      p {
        color: #2c3e50;
      }
      textarea,
      input {
        padding: 10px;
        margin-bottom: 15px;
        border: 1px solid #bdc3c7;
        border-radius: 4px;
        font-size: 14px;
        color: #333;
      }
      textarea {
        resize: none;
        height: 100px;
      }
      button {
        background-color: #4e7c9a;
        color: #fff;
        border: none;
        border-radius: 4px;
        padding: 10px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #2980b9;
        }
      }
    }

    #description {
      font-style: italic;
    }

    #videoUrl {
      font-weight: 700;
    }

    &__toggle-button {
      align-self: flex-start;
      background-color: #4e7c9a;
      color: #fff;
      border: none;
      border-radius: 4px;
      padding: 10px;
      cursor: pointer;
      transition: background-color 0.3s;

      &:hover {
        background-color: #2980b9;
      }
    }
    &__video-preview {
      width: 100%; // Ensure the video preview container takes up the full width of its parent
      max-width: 100%; // Prevents overflow beyond the parent element
      margin-top: 1rem;

    
    }


  }
}
.form-container__char-count{
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  font-size: .6rem;
  color: gray;
  font-family: 'Montserrat', sans-serif;
}